/** @format */
const headerObj = {
  Accept: "application/json",
  "Content-Type": "application/json"
}

const getBaseUrl = (isApi = true) => {
  const { protocol, hostname, port } = window.location
  const apiSuffix = isApi ? "/api/v1" : ""
  const host = _isLocalHost() && isApi ? "localhost:3001" : hostname
  const portString = _isLocalHost() && isApi ? "" : `:${port}`
  return `${protocol}//${host}${portString}${apiSuffix}`
}

const _isLocalHost = () => {
  const hostname = window.location.hostname
  return hostname.match(/local/) !== null
}

const statusCheck = async response => {
  if (!response.ok) {
    const errorInfo = await response.json()
    const { status, statusText } = response
    return Promise.reject({ status, statusText, errorInfo })
  } else {
    return response.json()
  }
}

const getCfgInfo = () => {
  const req = new XMLHttpRequest()
  let remoteUrl = "cfg"
  req.open("GET", `${getBaseUrl()}/util/` + remoteUrl, false)
  req.send(null)
  if (req.status === 200) {
    return JSON.parse(req.responseText)
  }
  return null
}

const locInfoUpd = (lat, lon) => {
  const req = new XMLHttpRequest()
  let remoteUrl = "updLoc"
  req.open("POST", `${getBaseUrl()}/map/` + remoteUrl, false)
  req.setRequestHeader("Accept", "application/json")
  req.setRequestHeader("Content-Type", "application/json")
  req.send(JSON.stringify({ lat: Number(lat), lon: Number(lon) }))
  return null
}

const locInfo = pingid => {
  const req = new XMLHttpRequest()
  let remoteUrl = "getLocation"
  req.open("POST", `${getBaseUrl()}/map/` + remoteUrl, false)
  req.setRequestHeader("Accept", "application/json")
  req.setRequestHeader("Content-Type", "application/json")
  req.send(JSON.stringify({ pingTypeId: Number(pingid) }))
  if (req.status === 200) {
    return JSON.parse(req.responseText)
  }
  return null
}

const getPings = (selat, selon, nwlat, nwlon) => {
  let remoteUrl = "showPings"
  return fetch(`${getBaseUrl()}/map/` + remoteUrl, {
    method: "POST",
    headers: headerObj,
    body: JSON.stringify({
      southEastPoint: { lat: Number(selat), lon: Number(selon) },
      northWestPoint: { lat: Number(nwlat), lon: Number(nwlon) }
    })
  })
    .then(statusCheck)
    .catch(() => {})
}

  const toggleMyInfo = () => {
    const req = new XMLHttpRequest()
    let remoteUrl = "myInfo"
    req.open("GET", `${getBaseUrl()}/users/` + remoteUrl, false)
    req.setRequestHeader("Accept", "application/json")
    req.setRequestHeader("Content-Type", "application/json")
    req.send()
    if (req.status === 200) {
      return
    }
  }

    const createPing = (pingTypeId) => {
    let remoteUrl = "create"
    return fetch(`${getBaseUrl()}/pings/` + remoteUrl, {
      method: "POST",
      headers: headerObj,
      body: JSON.stringify({
        pingTypeId: pingTypeId
      })
    })
      .then(statusCheck)
      .catch(() => {})
  }

const getPingFeed = (stidx, spidx) => {
  let remoteUrl = "liveFeed/lf"
  return fetch(`${getBaseUrl()}/pings/` + remoteUrl, {
    method: "POST",
    headers: headerObj,
    body: JSON.stringify({
      startIndex: Number(stidx),
      stopIndex: Number(spidx)
    })
  })
    .then(statusCheck)
    .catch(() => {})
}

const isLoggedIn = () => {
  let remoteUrl = "isLoggedIn"
  return fetch(`${getBaseUrl()}/users/` + remoteUrl)
    .then(statusCheck)
    .then(({ data }) => data)
    .catch(() => {})
}

const getTotalPingCount = () => {
  let remoteUrl = "totalPingCount/tpc"
  return fetch(`${getBaseUrl()}/pings/` + remoteUrl, {
    method: "POST",
    headers: headerObj
  })
    .then(statusCheck)
    .then(({ data }) => data)
    .catch(() => {})
}

const getLocation = () => {
  return fetch(`${getBaseUrl()}/map/me`)
    .then(statusCheck)
    .then(({ data }) => data)
    .catch(() => {})
}

export {
  locInfo,
  getPings,
  locInfoUpd,
  isLoggedIn,
  createPing,
  getCfgInfo,
  getBaseUrl,
  getLocation,
  getPingFeed,
  toggleMyInfo,
  getTotalPingCount
}
