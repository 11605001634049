/** @format */

import React, { useEffect, useState } from "react"
import { createPing, getCfgInfo } from "services/pingService"
import { Router, navigate } from "@reach/router"
import { About, Earth } from "components"

export const cfgKeys = getCfgInfo()
export const activePingsList = cfgKeys['aps']

const PingCreateOnLoad = ({ userLocation, onPingCreate, type, pid }) => {
  useEffect(() => {
    if (!userLocation) return
    createPing(pid, userLocation)
      .then(ping => onPingCreate(ping))
      .catch(() => window.location.assign("/"))
    //eslint-disable-next-line
  }, [userLocation])
  return null
}

function App() {
  const [loggedIn, setLoggedIn] = useState(true)
  const [createdPing, setCreatedPing] = useState(undefined)
  const [userLocation, setUserLocation] = useState(undefined)

  const handlePingCreate = ping => {
    setCreatedPing(ping)
  }
  useEffect(() => {
    if (!!createdPing) {
      navigate("/")
    }
  }, [createdPing])

  return (
    <Router>
      <Earth
        activePings={activePingsList}
        exact
        loggedIn={loggedIn}
        path="/"
        setLoggedIn={setLoggedIn}
        showPing={createdPing}
      />
      {activePingsList.map(pType => {
        return (
          <PingCreateOnLoad
            key={pType.type}
            userLocation={userLocation}
            path={`/${pType.type}`}
            onPingCreate={handlePingCreate}
            type={pType.type}
            pid={pType.id}
          />
        )
      })}
      <About
        activePings={activePingsList}
        loggedIn={loggedIn}
        onPingCreate={handlePingCreate}
        path="/about"
        setLoggedIn={setLoggedIn}
        userLocation={userLocation}
      />
    </Router>
  )
}

export default App
